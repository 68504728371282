import React from 'react';

const Main: React.FC = () => {
  return (
    <div className="container">
      <h2>
        Well... Thats a dogshit website.
      </h2>
      <br/>
      <p className="s">
        I tried coming up with text to put here, but failed.
        Trust me, whatever was here before is much worse,
        so this boring bit of text is, honestly, not that bad
        in comparison.
        <br/>
        <br/>
        <br/>
        Why don't you try and click other links?
      </p>
    </div>
  );
};

export default Main;
